import React from "react"
import { graphql } from "gatsby"

import Pagination from "../components-tr/pagination.tr"
import Layout from "../components/layout"
import Seo from "../components-tr/seo"
import Article from "../components-tr/article.tr"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPagePath, totalPages, pageNumber },
}) => {
  const posts = data.allWpPost.nodes
  const author = data.wpUser

  const metaTitle = pageNumber === 1 ? `Yazarlar | ${author.name?.split(" ")[0]}` : `Yazarlar | ${author.name?.split(" ")[0]} | Sayfa ${pageNumber}`
  const metaDescription = pageNumber === 1 ? author.description : `${author.description} | Sayfa ${pageNumber}`

  if (!posts.length) {
    return (
      <Layout navbarTheme="dark" className="bg-cream">
        <Seo title={metaTitle} description={metaDescription} />
        <p>İçerik bulunamadı.</p>
      </Layout>
    )
  }

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title={metaTitle} description={metaDescription} />
      <div className="pt-20 pb-10">
        <div className="container text-2xl text-center mx-auto lg:px-10 max-w-2xl">
          {author.avatar.url && 
            (<img 
              src={author.avatar.url} 
              alt={author.name} 
              className="rounded-full mx-auto" 
            />
          )}
          <h1 className="text-6xl p-10 font-bold text-blue">
            {author.name}
          </h1>
          <p className="font-light text-blue max-w-xs md:max-w-xl mx-auto">
            {author.description}
          </p>

          {/* <div className="text-center flex justify-center pt-[3.125rem]">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.85747 6.42432H0.984375V18.8852H4.85747V6.42432Z" fill="#1502FF" />
              <path d="M14.7103 6.10971C13.9748 6.08253 13.2457 6.25539 12.6006 6.60985C11.9556 6.96432 11.4188 7.48713 11.0474 8.12258H10.9934V6.4245H7.28125V18.8854H11.1466V12.7137C11.1466 11.0887 11.4573 9.51834 13.4695 9.51834C15.4527 9.51834 15.4824 11.3738 15.4824 12.8195V18.8854H19.343L19.3466 18.889V12.042C19.3472 8.69157 18.6261 6.10971 14.7103 6.10971Z" fill="#1502FF" />
              <path d="M2.89836 0.222188C2.45294 0.224189 2.01812 0.358219 1.64887 0.60733C1.27963 0.85644 0.992536 1.20945 0.823901 1.62171C0.655267 2.03398 0.612663 2.48699 0.701475 2.92347C0.790288 3.35994 1.00653 3.76029 1.32286 4.07387C1.63918 4.38746 2.04139 4.60021 2.47863 4.68522C2.91586 4.77023 3.36848 4.72369 3.77927 4.55147C4.19005 4.37926 4.54054 4.0891 4.78643 3.7177C5.03232 3.3463 5.16256 2.91033 5.16069 2.46491C5.15801 1.86753 4.91818 1.29567 4.49393 0.875089C4.06967 0.454512 3.49575 0.219662 2.89836 0.222188Z" fill="#1502FF" />
            </svg>
          </div> */}
        </div>
      </div>
      <div className="mx-2.5 md:mx-[3.375rem]">
        <ol className="lg:flex flex-wrap list-none">
          {posts.map((post, index) => {
            const title = post.title
            const featuredImage = {
              data: post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: post.featuredImage?.node?.alt || ``,
            }
            return (
              <Article key={index} post={post} title={title} featuredImage={featuredImage} />
            )
          })}
        </ol>
      </div>

      <Pagination
        rootSlug={`/tr/blog/author/${author.slug}`}
        totalPages={totalPages}
        previousPagePath={previousPagePath}
        nextPagePath={nextPagePath}
        currentPagePath={currentPagePath}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressAuthorPostArchiveTr(
    $offset: Int! 
    $postsPerPage: Int!
    $language: String!
    $authorId: String!
   ) {
    allWpPost(
      filter: {language: {slug: {eq: $language}}, author: {node: {id: {eq: $authorId}}}}
      sort: {fields: [date], order: DESC}
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY", locale: $language)
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    wpUser(id: {eq: $authorId}) {
      id
      slug
      avatar {
        url
      }
      name
      uri
      description
    }
  }
`
